import React from "react";
import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";


import { Layout } from "../components/layout/layout";
import { FirstSection } from "./dashboard/herosection";
import { DescriptionSection } from "./dashboard/descriptionsection";
import { ServiceSection } from "./dashboard/servicesection";
import { RoadmapSection } from "./dashboard/roadmapsection";
import { AnimeSection } from "./dashboard/animesection";
import { PreSaleInfo } from "./dashboard/presaleinfo";
import { MultiTokenInfo } from "./dashboard/multitokensection";

const Dashboard = () => {

  return (
    <Layout>
      <FirstSection />

      <DashboardWrapper>
        <DescriptionSection />
        <AnimeSection />
        <ServiceSection />
        <RoadmapSection />
        {/* <OurTeamSection /> */}
        {/* <SixthSection /> */}
        <PreSaleInfo />
        {/* <PartnersSection /> */}
        <MultiTokenInfo />
      </DashboardWrapper>
    </Layout >
  )
}

const DashboardWrapper = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  gap: 140,
  padding: '140px 0px',

  [theme.breakpoints.down('xl')]: {
    gap: 120,
    padding: '60px 0px',
  },

  [theme.breakpoints.down('lg')]: {
    gap: 90,
    padding: '60px 0px',
  },

  [theme.breakpoints.down('md')]: {
    gap: 60,
    padding: '60px 0px',
  },

  [theme.breakpoints.down('sm')]: {
    gap: 40,
    padding: '60px 0px',
  },
}))

export { Dashboard };