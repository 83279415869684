import React from "react";
import { useI18n } from "react-simple-i18n";
import { styled } from "@mui/material/styles";
import { Stack, Typography, useMediaQuery } from "@mui/material";

import 'react-slideshow-image/dist/styles.css';
import { GlobalSpacing, SectionGap, SectionSpacing } from "../../components/layout/common";
import { animeImg1, animeImg2, animeImg3, animeImg4 } from "../../assets/image";

const AnimeSection = () => {
	const { t } = useI18n();

	return (
		<GlobalSpacing>
			<SectionSpacing>
				<AnimeSectionWrapper>
					<Typography variant="h2" textAlign='center'>
						{t("body.anime.title")}
					</Typography>

						<Stack direction="row" flexWrap="wrap" gap={2} justifyContent="center">
							<AnimeItem>
								<img alt="" src={animeImg1} />
							</AnimeItem>

							<AnimeItem>
								<img alt="" src={animeImg2} />
							</AnimeItem>

							<AnimeItem>
								<img alt="" src={animeImg3} />
							</AnimeItem>

							<AnimeItem>
								<img alt="" src={animeImg4} />
							</AnimeItem>
						</Stack>
				</AnimeSectionWrapper>
			</SectionSpacing>
		</GlobalSpacing>
	)
}

const AnimeSectionWrapper = styled(SectionGap)({
	display: "flex",
	flexDirection: "column",
})

const AnimeItem = styled(Stack)({
	display: "flex",
	flexDirection: "column",
	padding: "0px 10px",
	userSelect: "none",

	img: {
		width: "300px",
		height: "500px",
		objectFit: "cover",
	}
})

export { AnimeSection };